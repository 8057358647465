import React, { useState } from "react";

const SendMessage = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");

  const sendMessage = async () => {
    try {
      // Log the values of phoneNumber and message
      console.log("Phone Number:", phoneNumber);
      console.log("Message:", message);

      const response = await fetch("http://localhost:8000/send-message", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          message: message,
        }),
      });

      const data = await response.json();

      // Log the response from the server for debugging
      console.log("Response from server:", data);

      if (data.messageSid) {
        setStatus(`Message sent successfully! SID: ${data.messageSid}`);
      } else {
        setStatus(`Error: ${data.error}`);
      }
    } catch (error) {
      setStatus(`Error: ${error.message}`);
      console.error("Error during message send:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Send WhatsApp Message</h1>
      <form onSubmit={handleSubmit} className="bg-light p-4 rounded shadow">
        <div className="mb-3">
          <label className="form-label">Phone Number</label>
          <input
            type="text"
            className="form-control"
            placeholder="Recipient's phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Message</label>
          <textarea
            className="form-control"
            placeholder="Enter your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary w-100">
          Send Message
        </button>
      </form>
      {status && <p className="mt-3 text-center">{status}</p>}
    </div>
  );
};

export default SendMessage;
