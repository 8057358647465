import React, { useState } from "react";
import axios from "axios";

const SendWhatsAppMessage = () => {
  const [status, setStatus] = useState(""); // To store the message sending status
  const [loading, setLoading] = useState(false); // To show loading state

  const sendWhatsappMessage = async () => {
    setLoading(true); // Set loading state to true when the message is being sent
    setStatus(""); // Reset status message before sending

    const customerNumber = "7502515414"; // Customer's mobile number (in the format: +1234567890)
    const companyNumber = "+12183355144"; // Your Twilio WhatsApp number (Twilio provided, e.g., +12183355144)

    const message = `Hi, Your booking for the turf has been confirmed!`;

    try {
      const response = await axios.post(
        `https://api.twilio.com/2010-04-01/Accounts/ACdbb9703cd82fdfd0040e6ca060001d6e/Messages.json`,
        new URLSearchParams({
          To: `whatsapp:${customerNumber}`, // Ensure customer number is verified in your Twilio account
          From: `whatsapp:${companyNumber}`,
          Body: message,
        }),
        {
          auth: {
            username: "ACdbb9703cd82fdfd0040e6ca060001d6e", // Twilio Account SID
            password: "76a7e47e2a425ec599b885bf62d51a7c", // Twilio Auth Token
          },
        }
      );

      console.log("WhatsApp Message Sent:", response.data);

      setStatus("Message sent successfully!"); // Update status after message is sent
    } catch (error) {
      console.error("Error sending WhatsApp message:", error);
      setStatus("Failed to send message. Please try again."); // Set error status if sending fails
    } finally {
      setLoading(false); // Set loading state to false after the process is finished
    }
  };

  return (
    <div className="container">
      <h1>Send WhatsApp Message</h1>
      <button onClick={sendWhatsappMessage} disabled={loading}>
        {loading ? "Sending..." : "Send Message"}
      </button>
      {status && (
        <div
          className={`status-message ${
            status.includes("success") ? "success" : "error"
          }`}
        >
          {status}
        </div>
      )}
    </div>
  );
};

export default SendWhatsAppMessage;
